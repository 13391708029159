import meter1 from "../assets/img/meter1.svg";
import meter2 from "../assets/img/meter2.svg";
import meter3 from "../assets/img/meter3.svg";
import cimg from "../assets/img/c.png";
import cppimg from "../assets/img/cpp.png";
import pyimg from "../assets/img/py.png";
import masmimg from "../assets/img/masm.png";

import houimg from "../assets/img/hou.png";
import mayaimg from "../assets/img/maya.png";
import ueimg from "../assets/img/ue.png";
import unityimg from "../assets/img/unity.png";

import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import arrow1 from "../assets/img/arrow1.svg";
import arrow2 from "../assets/img/arrow2.svg";
import colorSharp from "../assets/img/color-sharp.png"

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="skills">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>Skills</h2>
                        <p>My skills span across multiple fields in Computer Graphics & Computer Science.<br></br> My favorite topics are low level systems programming & 3D computer graphics.</p>
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                            <div className="item">
                                <img src={cimg} alt="Image" />
                                <h5>C</h5>
                            </div>
                            <div className="item">
                                <img src={cppimg} alt="Image" />
                                <h5>C++</h5>
                            </div>
                            <div className="item">
                                <img src={pyimg} alt="Image" />
                                <h5>Python</h5>
                            </div>
                            <div className="item">
                                <img src={masmimg} alt="Image" />
                                <h5>MASM64</h5>
                            </div>
                        </Carousel>
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                            <div className="item">
                                <img src={houimg} alt="Image" />
                                <h5>Houdini</h5>
                            </div>
                            <div className="item">
                                <img src={ueimg} alt="Image" />
                                <h5>Unreal Engine</h5>
                            </div>
                            <div className="item">
                                <img src={unityimg} alt="Image" />
                                <h5>Unity Engine</h5>
                            </div>
                            <div className="item">
                                <img src={mayaimg} alt="Image" />
                                <h5>Maya</h5>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
      {/*<img className="background-image-left" src={colorSharp} alt="Image" /> */}
    </section>
  )
}

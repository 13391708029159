import { Col } from "react-bootstrap";
import Button from 'react-bootstrap/Button';

export const ProjectCard = ({ title, description, imgUrl, link }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <div className="proj-imgbx">
        <img src={imgUrl} />
        <div className="proj-txtx">
          <h4>{description}</h4>
          <Button href={link} variant="primary" target="_blank">{title}</Button>{' '}
        </div>
      </div>
    </Col>
  )
}

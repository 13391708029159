import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import projImg1 from "../assets/img/project-img1.png";
import projImg2 from "../assets/img/project-img2.png";
import projImg3 from "../assets/img/project-img3.png";


import img_castle from "../assets/img/img_castle.png";
import img_ecorche from "../assets/img/img_ecorche.png";
import img_godzilla from "../assets/img/img_godzilla.png";
import img_robo from "../assets/img/img_robo.png";
import img_swan from "../assets/img/img_swan.jpg";
import img_warrior from "../assets/img/img_warrior.png";

import img_binary from "../assets/img/binary.png";

import colorSharp2 from "../assets/img/color-sharp2.png";
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Projects = () => {

  const projects = [
    {
      title: ">",
      description: "TransformersVR",
      imgUrl: img_robo,
      link: "https://www.google.com",
    },
    {
      title: ">",
      description: "Warrior",
      imgUrl: img_warrior,
      link: "https://www.google.com",
    },
    {
      title: ">",
      description: "Ecorche",
      imgUrl: img_ecorche,
      link: "https://www.google.com",
    },
    {
      title: ">",
      description: "Swan",
      imgUrl: img_swan,
      link: "https://www.google.com",
    },
    {
      title: ">",
      description: "Anor Londo",
      imgUrl: img_castle,
      link: "https://www.google.com",
    },
    {
      title: ">",
      description: "Godzilla 1998",
      imgUrl: img_godzilla,
      link: "https://www.google.com",
    },
  ];

  const codeprojects = [
    {
      title: ">",
      description: "SHAR",
      imgUrl: img_binary,
      link: "https://github.com/IbrahimHindawi/ibra_shar",
    },
    {
      title: ">",
      description: "RTSR",
      imgUrl: img_binary,
      link: "https://github.com/IbrahimHindawi/rtsr",
    },
    {
      title: ">",
      description: "Haikal",
      imgUrl: img_binary,
      link: "https://github.com/IbrahimHindawi/haikal",
    },
  ];

  const gameprojects = [
    {
      title: ">",
      description: "SpaceShooter(OpenGL)",
      imgUrl: img_binary,
      link: "https://github.com/IbrahimHindawi/spaceshooter",
    },
  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "": ""}>
                <h2>Projects</h2>
                <p>Various personal projects revolving around computer graphics, computer science & game engine programming.</p>
                <Tab.Container id="projects-tabs" defaultActiveKey="first">
                  <Nav variant="pills" className="nav-pills mb-5 justify-content-center align-items-center" id="pills-tab">
                    <Nav.Item>
                      <Nav.Link eventKey="first">Art</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="second">Code</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="third">Game</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content id="slideInUp" className={isVisible ? "" : ""}>
                    <Tab.Pane eventKey="first">
                      <p>MODELING | TEXTURING | RIGGING | ANIMATION | RENDERING | VFX | PROCEDURAL</p>
                      <Row>
                        {
                          projects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <p>Tools used for game development & computer graphics.</p>
                      <Row>
                        {
                          codeprojects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <p>Custom Game Engine Programming</p>
                      <Row>
                        {
                          gameprojects.map((project, index) => {
                            return (
                              <ProjectCard
                                key={index}
                                {...project}
                                />
                            )
                          })
                        }
                      </Row>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2}></img>
    </section>
  )
}
